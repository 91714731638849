import styled from "styled-components";
import React from "react";
import { Header } from "./header/header";
import { MainLogoBlock } from "./main/main-logo-block/mainLogoBlock";

const WrapMainBlock = styled.div`
  background-color: #e0e4d9;
  margin-top: 60px; /* Учтем высоту фиксированного header */
  height: calc(100vh - 60px); /* Высота остального пространства */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  border: 1px solid black;
  width: 1200px;
  height: 100vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainPageWrapper = () => {
  return (
    <MainWrap>
      <Header />
      <WrapMainBlock>
        <MainContainer>
          <MainLogoBlock />
        </MainContainer>
      </WrapMainBlock>
    </MainWrap>
  );
};
