import styled from "styled-components";
import React from "react";

const HeaderWrap = styled.div`
  background-color: #e0e4d9;
  display: flex;
  position: fixed;
  border: 1px solid black;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: end;
  border: 1px solid black;
  top: 0;
  left: 0;
`;

const HeaderButtonBlock = styled.div`
  border: 1px solid red;
  width: 500px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 0;
`;

const HeaderButtons = styled.button``;

export const Header = () => {
  return (
    <>
      <HeaderWrap>
        <HeaderButtonBlock>
          <HeaderButtons>О Нас</HeaderButtons>
          <HeaderButtons>Галерея</HeaderButtons>
          <HeaderButtons>Продукция</HeaderButtons>
          <HeaderButtons>Контакты</HeaderButtons>
        </HeaderButtonBlock>
      </HeaderWrap>
    </>
  );
};
