import { MainPageWrapper } from "./components/main-page.style.jsx";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://fonts.cdnfonts.com/css/phenomena";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  return <MainPageWrapper />;
}

export default App;
