import styled from "styled-components";
import React from "react";

const LogoBlockWrap = styled.div`
  width: 100%;
  border: 1px solid red;
  height: 400px;
  display: flex;
  align-items: center;
`;

const LogoBlockText = styled.div`
  width: 550px;
  border: 1px solid red;
  font-family: "Phenomena", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoMainWord = styled.span`
  font-weight: 600;
  font-size: 48px;
`;

const LogoSecondWord = styled.span`
  font-weight: 600;
  font-size: 32px;
`;

export const MainLogoBlock = () => {
  return (
    <>
      <LogoBlockWrap>
        <LogoBlockText>
          <LogoMainWord>Добро пожаловать</LogoMainWord>
          <br />
          <LogoSecondWord>
            на сайт экологически чистого рыбного хозяйства!
          </LogoSecondWord>
        </LogoBlockText>
      </LogoBlockWrap>
    </>
  );
};
